const minPoseConfidence = 0.1
const minPartConfidence = 0.7

export function drawGlasses(ctx, kp, glassImagePath, x_offset, y_offset, image_size, debug) {

	const EYE_TO_GLASS_RATIO = 2.2
	const BASE_EYE_DISTANCE = 100



	const ry = kp[2].position.y
	const rx = kp[2].position.x

	const ly = kp[1].position.y
	const lx = kp[1].position.x

	const base_image = new Image();
	base_image.src = glassImagePath;

	//count radians for head rotationfrom eyes points
	let angleInRad = Math.atan2(ry - ly, rx - lx)

	let dx = rx - lx;
	let dy = ry - ly;
	let eye_distance = Math.sqrt( dx * dx + dy  * dy)
	let eye_distance_scale =  eye_distance / BASE_EYE_DISTANCE

	let image_width_height_ratio =  base_image.height / base_image.width

	// COMPUTE ACTUAL IMAGE SIZE ON CANVAS
	let image_width = image_size  * eye_distance * EYE_TO_GLASS_RATIO
	let image_height = image_width * image_width_height_ratio


	var sizeWidth = ctx.canvas.clientWidth
	var sizeHeight = ctx.canvas.clientHeight

	if (debug) {
		//LINE - DEBUG
		ctx.beginPath()
		ctx.moveTo(lx, ly)
		ctx.lineTo(rx, ry)
		ctx.stroke()

	}

	ctx.save();

	ctx.translate(((lx+rx)/2), (ly+ry)/2) // change origin
  ctx.rotate(Math.PI + angleInRad)
  ctx.translate(-x_offset, -y_offset) // change offset



  ctx.drawImage(base_image, -image_width/2, -image_height/2, image_width, image_height)

  ctx.restore()

}

export function drawKeypoints(keypoints, minConfidence, ctx, scale, glassImagePath, x_offset, y_offset, image_size, debug) {



  for (let i = 0; i < keypoints.length; i++) {
    const keypoint = keypoints[i]

    if (keypoint.score < minConfidence) {
      continue
    }


	if(keypoint.part === "nose"){
		drawGlasses(ctx, keypoints, glassImagePath, x_offset, y_offset, image_size, debug)
	}

	if (debug){
		if(["nose", "leftEye", "rightEye", "leftEar", "rightEar"].includes(keypoint.part)){
			const {y, x} = keypoint.position
			ctx.fillRect(x,y,5,5)
			ctx.save()
    	//drawPoint(ctx, y * scale, x * scale, 10,keypoints,glassImagePath,x_offset,y_offset,image_size)
		}
	}

	//console.log(keypoint.part)


  }
}

export function repaint(video, pose, ctxObj) {
	const glassImagePath = this.props.glassImagePath
	const x_drive = this.props.xdrive
	const y_drive = this.props.ydrive
	const image_size = this.props.imagesize
	const debug = this.props.debug


  const { ctx, canvasWidth, canvasHeight } = ctxObj
	ctx.clearRect(0, 0, canvasWidth, canvasHeight)
	ctx.save()
	ctx.scale(-1, 1)
	ctx.translate(-canvasWidth, 0)
	ctx.drawImage(video, 0, 0, canvasWidth, canvasHeight)
	ctx.restore()

	// isPalmsMeet(pose)
	//console.log(pose.keypoints);
	if (pose.score >= minPoseConfidence) {
		drawKeypoints(pose.keypoints, minPartConfidence, ctx, 1, glassImagePath, x_drive, y_drive, image_size, debug)
	}
}
