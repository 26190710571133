import React, { Component } from "react";
import { Redirect } from 'react-router';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Route,NavLink,HashRouter} from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import ToggleButton from '@material-ui/lab/ToggleButton';
import CodeIcon from '@material-ui/icons/Code';

import axios from 'axios';

import MainCanvas from './components/MainCanvas'

class Mirror extends Component {

  constructor(props) {
    super(props)

    this.state = {
      glassImagePath: '', // 'https://glasses.ithelps.sk/imgs/realgl.png'
      x_drive: 0,
      y_drive: 0,
      image_size: 1,
      debug: false,
      id : props.match.params.id === null ? null : props.match.params.id,
      api_https_prefix: "https://virtualnezrkadlo.sk/api/mirrorJson/",
      error: ""
    } 
  }
  /* API RESPONSE
  created_at: "2020-09-25 12:38:39"
description: "123"
external_link_path: null
file_name: "realgl.png"
file_path: "RUGZujCYxgFlIv9YdWs9DuvItA4KLgHNSTe6JWzM.png"
final_path: "localhost:8000/storage/RUGZujCYxgFlIv9YdWs9DuvItA4KLgHNSTe6JWzM.png"
id: 4
image_height: "0"
image_width: "0"
name: "123"
position_left: "0"
position_top: "0"
public: null
updated_at: "2020-09-25 12:38:39"
user_id: "3"
__proto__: Object
  */ 


  componentDidMount() {
    console.log(this.state.api_https_prefix + this.state.id)    
    if (this.state.id !== null) {
      let api_url = this.state.api_https_prefix + this.state.id
      fetch(api_url)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            glassImagePath: result.final_path,
            x_drive: result.position_left,
            y_drive: result.position_top
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error: "could not load API from " + api_url
          });
        }
      )
    }

      const id = this.getUrlVars('id');
      if(id)
        axios.get(`http://localhost:8000/api/mirrorImage/`+id)
          .then(res => {
            this.setState({ glassImagePath : res.data});
          })
    }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    if(vars=={})
      return vars;
    return false
}


  render() {

    return (
      <div>
      <Paper style={{padding:"20px",marginTop:"30px"}} align="center">
        <MainCanvas glassImagePath={this.state.glassImagePath}
          xdrive={this.state.x_drive}
           ydrive={this.state.y_drive}
            imagesize={this.state.image_size}
             debug={this.state.debug}/>
      </Paper >
      <Grid container spacing={2} justify="center" style={{marginTop:"20px"}}>
      <Grid item xs={3} style={{textAlgin:'center'}} >
        {this.state.id ||
        <TextField
            id="standard-name"
            label="Url na obrázok"
            style={{margin:'auto'}}
            value={this.state.glassImagePath}
            onChange={(e) => {this.setState({glassImagePath:e.target.value})}}
          />
          
        }
      </Grid>

      <Grid item xs={3}>
        <div style={{margin:'auto'}} >
          <RemoveIcon style={{fontSize:'60px',cursor:"pointer"}} onClick={() => {this.setState({image_size:this.state.image_size*0.9})}} />
          <AddIcon style={{fontSize:'60px',cursor:"pointer"}} onClick={() => {this.setState({image_size:this.state.image_size*1.1})}} />
        </div>
      </Grid>

      <Grid container xs={3} style={{textAlgin:'center'}} >
        <div>
          <KeyboardArrowUpIcon style={{fontSize:'60px',cursor:"pointer"}} onClick={() => {this.setState({y_drive:this.state.y_drive+10});}} />
          <KeyboardArrowLeftIcon style={{fontSize:'60px',cursor:"pointer"}} onClick={() => {this.setState({x_drive:this.state.x_drive+10});}} />
          <KeyboardArrowRightIcon style={{fontSize:'60px',cursor:"pointer"}} onClick={() => {this.setState({x_drive:this.state.x_drive-10});}} />
          <KeyboardArrowDownIcon style={{fontSize:'60px',cursor:"pointer"}} onClick={() => {this.setState({y_drive:this.state.y_drive-10});}} />
        </div>
        </Grid>

        <Grid container xs={3} style={{textAlgin:'center'}} >
          <CodeIcon style={{fontSize:'60px', cursor:"pointer", color:this.state.debug ? "red" : "black"}}  onClick={() => {this.setState({debug: !this.state.debug})}}/>
        </Grid>

      </Grid>

      </div>

    )
  }
}

export default Mirror;
