import React, { Component } from "react";
import {Route,BrowserRouter as Router} from "react-router-dom";


import Mirror from "./Mirror";

class Main extends Component {

  render() {

    return (
      <Router>
        <Route exact path="/:id" component={Mirror}/>
        <Route exact path="/" component={Mirror}/>
          <div style={{margin:'auto',textAlign:'center'}}>
            <p><b>VirtualneZrkadlo.sk 2020</b></p>
          </div>
      </Router>
    );
  }
}

export default Main;
