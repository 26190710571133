import React, { Component } from 'react'
import './MainCanvas.css'

import * as posenet from '@tensorflow-models/posenet'
import { repaint } from '../../utilities/Canvas'
import loadVideo from '../../utilities/Camera'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

class MainCanvas extends Component {
	constructor(props) {
		super(props)

		this.state = {
			input: '',
			mainColor: 'rgba(180, 0, 0, 0)',
			loader: true,

		}

	}

	componentDidMount() {
		this.bindPage.bind(this)();

	}


	async bindPage() {


		let video

		try {
			video = await loadVideo()
		} catch (e) {
			throw e
		}

		const net = await posenet.load(0.75)
		const canvas = document.getElementById('output')
		const ctx = canvas.getContext('2d')
		const canvasWidth = window.innerWidth > 1000 ? window.innerWidth / 2.5 : window.innerWidth / 1.3
		const canvasHeight = window.innerWidth > 1000 ? window.innerHeight / 2 : window.innerWidth / 1.5
		const glassImagePath = this.props.glassImagePath
		const debug = this.props.debug
		canvas.width = canvasWidth
		canvas.height = canvasHeight






		async function poseDetection() {


			const flipHorizontal = true
			const imageScaleFactor = 0.5 // default 0.5
			const outputStride = 16 // default 16


			const pose = net.estimateSinglePose(video, imageScaleFactor, flipHorizontal, outputStride)

			pose.then((pose) => repaint.bind(this)(video, pose, {
				ctx,
				canvasWidth,
				canvasHeight,

			},
				poseDetection,

			))

			requestAnimationFrame(poseDetection.bind(this))


		}

		poseDetection.bind(this)();

		this.setState((state, props) => {
		  return {
				loader: false

				};
		});
	}

	render () {
		return (
			<div>
				<Grid  justify="center">

					{ this.state.loader ?'Načítavam virtuálne zrkadlo... Prosím počkajte.' : null }
					{ this.state.loader ?<CircularProgress  /> : null }
				</Grid>
				<video id="video"></video>
				<canvas id="output" />
			</div>
		)
	}

}

export default MainCanvas
